import { graphql } from "gatsby";
import React from "react";
import Terms from "../../components/Terms";
import { themeMode } from "../../constants/strings";

export const query = graphql`
    query {
        content: allGhostPage(
            filter: {
                tags: {
                    elemMatch: { name: { eq: "#metro-stc-terms-and-conditions" } }
                }
            }
        ) {
            nodes {
                title
                html
            }
        }
    }
`;

const MetroTermsAndConditionsPage = (props) => {
    let content = props.data.content.nodes[0].html;
    let title = props.data.content.nodes[0].title;

    const location = props.location;

    // This has been kept to avoid rehydration issues
    // this page is being used in Consumer App to show TnC without header and footer
    if (typeof window == `undefined`) {
        return <Terms hideLayout={true} data={content} title={title} />;
    }

    return (
        <Terms
            hideLayout={location.search.includes(themeMode)}
            data={content}
            title={title}
        />
    );
};

export default MetroTermsAndConditionsPage;
